import React, { useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { Row, Col, Form, Button } from "react-bootstrap"

const SignUp = () => {
  const [isSignUp, setIsSignUp] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const scrollToRef = ref => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }
  const myRef = useRef(null)

  const onSubmit = (data, event) => {
      setIsSignUp(true)
      scrollToRef(myRef)
  }

  
  return (
    <section className="container sign-up" ref={myRef}>
      {isSignUp ? (
        <div className="message-block">
          <h5 className="first-letter submission-message text-center">
            DING! WE GOT YOUR SUBMISSION—BE SURE TO KEEP AN EYE ON YOUR INBOX TO
            SEE IF YOU’RE A WINNER!
          </h5>
        </div>
      ) : (
        <>
          <h5 className="text-white">
            ENTER THE ORBIT® SMOOCHING SWEATER SWEEPSTAKES.
          </h5>
          <p className="text-white">
            Type in your info for a chance to get your ding back.
          </p>
          <Form className="home" onSubmit={handleSubmit(onSubmit)}>
            <Row noGutters className="justify-content-center">
              <Col>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="firstName" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="60"
                        className="rounded-0"
                        placeholder="First Name"
                        {...register("firstname", { required: true })}
                      />
                      {errors.firstname && (
                        <span className="input-error">
                          First Name is required
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="lastName" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="60"
                        className="rounded-0"
                        placeholder="Last Name"
                        {...register("lastname", { required: true })}
                      />
                      {errors.lastname && (
                        <span className="input-error">
                          Last Name is required
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="address" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="300"
                        className="rounded-0"
                        placeholder="Address"
                        {...register("address", { required: true })}
                      />
                      {errors.address && (
                        <span className="input-error">Address is required</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="address2" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="300"
                        className="rounded-0"
                        placeholder="Address 2"
                        {...register("address2")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="city" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="60"
                        className="rounded-0"
                        placeholder="City"
                        {...register("city", { required: true })}
                      />
                      {errors.city && (
                        <span className="input-error">City is required</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="state" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="60"
                        className="rounded-0"
                        placeholder="State"
                        {...register("state", { required: true })}
                      />
                      {errors.state && (
                        <span className="input-error">State is required</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="zipcode" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="60"
                        className="rounded-0"
                        placeholder="Zip-code"
                        {...register("zipcode", {
                          required: "Zip-code is required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Invalid Zip-code",
                          },
                        })}
                      />
                      {errors.zipcode && (
                        <span className="input-error">
                          {errors.zipcode.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="email" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="80"
                        className="rounded-0"
                        placeholder="E-mail Address"
                        {...register("email", {
                          required: "Email ID is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid Email Address",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="input-error">
                          {errors.email.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="phone" className="mb-4">
                      <Form.Control
                        type="text"
                        maxLength="80"
                        className="rounded-0"
                        placeholder="Phone Number"
                        {...register("phone", {
                          required: "Phone Number is required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Invalid Phone Number",
                          },
                        })}
                      />
                      {errors.phone && (
                        <span className="input-error">
                          {errors.phone.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Row className="align-center">
                    <Col md={1} className="col-2">
                      <input
                        type="checkbox"
                        id="termsConsent"
                        className="mt-1 checkbox-consent"
                        required
                        onInvalid={e => {
                          e.target.validity.valueMissing
                            ? e.target.setCustomValidity(
                                "Please agree T&C's and confirm"
                              )
                            : e.target.setCustomValidity("")
                        }}
                        onClick={e => {
                          e.target.validity.valueMissing
                            ? e.target.setCustomValidity(
                                "Please agree T&C's and confirm"
                              )
                            : e.target.setCustomValidity("")
                        }}
                        // {...register("marketingConsent")}
                      />
                    </Col>
                    <Col
                      md={11}
                      className="col-10 p-0 align-self-center text-left"
                    >
                      <label htmlFor="termsConsent">
                        <p className="mb-2 checkbox-msg text-white">
                          I accept the{" "}
                          <a
                            className="term-link"
                            href="/Orbit Sweater Sweepstakes Rules FINAL.pdf"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Terms and Conditions
                            <span className="visually-hidden">
                              Document(215kb)
                            </span>
                          </a>
                          <span className="visually-hidden">
                            Opens a new window
                          </span>{" "}
                          and adhere to the age restrictions.
                        </p>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="align-center">
                    <Col md={1} className="col-2">
                      <input
                        type="checkbox"
                        id="marketingConsent"
                        className="mt-1 checkbox-consent"
                        {...register("marketingConsent")}
                      />
                    </Col>
                    <Col
                      md={11}
                      className="col-10 p-0 align-self-center text-left"
                    >
                      <label htmlFor="marketingConsent">
                        <p className="mb-2 checkbox-msg text-white">
                          I would like to receive future marketing
                          communications from ORBIT® Gum and other Mars
                          Wrigley’s brands via the email provided. You can
                          withdraw this consent at any time.
                        </p>
                      </label>
                    </Col>
                  </Row>
                </Form.Group>
                <Button size="lg" className="mt-5" type="submit">
                  SUBMIT
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </section>
  )
}

export default SignUp
