import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "react-bootstrap"

import useHover from "./useHover"

import Sizing from "../images/sizing.png"
import Measurements from "../images/measurements.png"
import MistletoeShirt from "../images/sweater-mistletoe-shirt.png"
import Mistletoe from "../images/sweater-mistletoe.png"
import RemoteIn from "../images/sweater-remote-in.png"
import RemoteOut from "../images/sweater-remote-out.png"
import Sequin from "../images/sweater-sequin.png"
import LightsOff from "../images/sweater-lightsoff.png"
import DispenserOne from "../images/sweater-dispenser-anim-1.png"
import DispenserTwo from "../images/sweater-dispenser-anim-2.png"
import DispenserThree from "../images/sweater-dispenser-anim-3.png"
import DispenserFour from "../images/sweater-dispenser-anim-4.png"
import DispenserFive from "../images/sweater-dispenser-anim-5.png"
import DispenserSix from "../images/sweater-dispenser-anim-6.png"

import Peppermint from "../images/peppermint.svg"
import Ding from "../images/ding.png"

const SweaterAnimation = () => {
  const [mistletoe, isMistletoe] = useHover()
  const [mistletoeNum, isMistletoeNum] = useHover()
  const [sequin, isSequin] = useHover()
  const [sequinNum, isSequinNum] = useHover()
  const [lights, isLights] = useHover()
  const [lightsNum, isLightsNum] = useHover()
  const [remote, isRemote] = useHover()
  const [remoteNum, isRemoteNum] = useHover()
  const [dispenser, isDispenser] = useHover()
  const [dispenserNum, isDispenserNum] = useHover()
  const [peppermint, isPeppermint] = useHover()
  const [peppermintNum, isPeppermintNum] = useHover()

  useEffect(() => {
    var pCanvas = document.getElementById("peppermint-Canvas")
    var peppermintCanvas = pCanvas.getContext("2d")
    peppermintCanvas.beginPath()
    peppermintCanvas.moveTo(0, 0)
    peppermintCanvas.lineTo(220, 0)
    peppermintCanvas.lineTo(250, 20)
    peppermintCanvas.lineWidth = 1.5
    peppermintCanvas.strokeStyle = "#fff"
    peppermintCanvas.stroke()

    var mCanvas = document.getElementById("mistletoe-Canvas")
    var mistletoeCanvas = mCanvas.getContext("2d")
    mistletoeCanvas.beginPath()
    mistletoeCanvas.moveTo(0, 0)
    mistletoeCanvas.lineTo(30, 30)
    mistletoeCanvas.lineTo(200, 30)
    mistletoeCanvas.lineWidth = 1.5
    mistletoeCanvas.strokeStyle = "#fff"
    mistletoeCanvas.stroke()

    var rCanvas = document.getElementById("remote-Canvas")
    var remoteCanvas = rCanvas.getContext("2d")
    remoteCanvas.beginPath()
    remoteCanvas.moveTo(0, 0)
    remoteCanvas.moveTo(0, 10)
    remoteCanvas.lineTo(255, 10)
    remoteCanvas.lineTo(270, 0)
    remoteCanvas.lineWidth = 1.5
    remoteCanvas.strokeStyle = "#fff"
    remoteCanvas.stroke()

    var sCanvas = document.getElementById("sequin-Canvas")
    var sequinCanvas = sCanvas.getContext("2d")
    sequinCanvas.beginPath()
    sequinCanvas.moveTo(0, 0)
    sequinCanvas.lineTo(25, 25)
    sequinCanvas.lineTo(340, 25)
    sequinCanvas.lineWidth = 1.5
    sequinCanvas.strokeStyle = "#fff"
    sequinCanvas.stroke()

    var lCanvas = document.getElementById("lights-Canvas")
    var lightsCanvas = lCanvas.getContext("2d")
    lightsCanvas.beginPath()
    lightsCanvas.moveTo(0, 0)
    lightsCanvas.moveTo(0, 25)
    lightsCanvas.lineTo(105, 25)
    lightsCanvas.lineTo(125, 0)
    lightsCanvas.lineWidth = 1.5
    lightsCanvas.strokeStyle = "#fff"
    lightsCanvas.stroke()

    var dCanvas = document.getElementById("dispenser-Canvas")
    var dispenserCanvas = dCanvas.getContext("2d")
    dispenserCanvas.beginPath()
    dispenserCanvas.moveTo(0, 0)
    dispenserCanvas.lineTo(45, 70)
    dispenserCanvas.lineTo(230, 70)
    dispenserCanvas.lineWidth = 1.5
    dispenserCanvas.strokeStyle = "#fff"
    dispenserCanvas.stroke()
  }, [])

  return (
    <section className="position-relative text-center sweater-animation container">
      <div className="mobile position-relative mb-4">
        <Button
          size="lg"
          className="mb-2 peppermint-button"
          type="submit"
          onClick={() => {
            document
              .getElementById("peppermint")
              .classList.add("peppermint-anim")
            document.getElementById("flash").classList.add("peppermint-flash")
            document.getElementById("mistletoe").classList.remove("ring")
            document.getElementById("remote").classList.remove("mobile-remote")
            document
              .getElementById("dispenser")
              .classList.remove("mobile-dispenser-anim")

            document.getElementById("sequin").classList.remove("sequinfade")
            setTimeout(() => {
              document
                .getElementById("peppermint")
                .classList.remove("peppermint-anim")
              document
                .getElementById("flash")
                .classList.remove("peppermint-flash")
            }, 2000)
          }}
        >
          Peppermint scented!
        </Button>
        <Button
          size="lg"
          className="mb-2 mistletoe-button"
          type="submit"
          onClick={() => {
            document.getElementById("mistletoe").classList.add("ring")
            document.getElementById("remote").classList.remove("mobile-remote")
            document
              .getElementById("peppermint")
              .classList.remove("peppermint-anim")
            document
              .getElementById("dispenser")
              .classList.remove("mobile-dispenser-anim")

            document.getElementById("sequin").classList.remove("sequinfade")
            setTimeout(
              () =>
                document.getElementById("mistletoe").classList.remove("ring"),
              5000
            )
          }}
        >
          On-the-go mistletoe
        </Button>
        <Button
          size="lg"
          className="mb-5 remote-button"
          type="submit"
          onClick={() => {
            document.getElementById("mistletoe").classList.remove("ring")
            document.getElementById("remote").classList.add("mobile-remote")
            document
              .getElementById("peppermint")
              .classList.remove("peppermint-anim")
            document
              .getElementById("dispenser")
              .classList.remove("mobile-dispenser-anim")

            document.getElementById("sequin").classList.remove("sequinfade")
            setTimeout(
              () =>
                document
                  .getElementById("remote")
                  .classList.remove("mobile-remote"),
              2500
            )
          }}
        >
          Light up!—With the pocket{" "}
          <span className="d-block">sleeve remote holder.</span>
        </Button>
      </div>
      <div className="position-relative">
        <img
          className="position-relative sweater-mistletoe-shirt"
          src={MistletoeShirt}
          alt="Sweater Shirt"
        />
        <div className="sweater-sizing">
          <img
            className="position-absolute sizing"
            src={Sizing}
            alt="Sweater Sizes"
            id="size"
            onClick={() => {
              document.getElementById("measurements").classList.add("active")
            }}
          />
          <img
            id="measurements"
            className="position-absolute sweater-measurements"
            src={Measurements}
            alt="Sweater measurements"
          />
        </div>
        {/* 01. Peppermint scented! */}
        <>
          <div className="desktop">
            <div
              ref={peppermintNum}
              className={`${"position-absolute text-left feature-one"} ${
                isPeppermint ? "feature-block" : ""
              } ${isPeppermintNum ? "feature-block" : ""}`}
            >
              <h6 className="first-letter feature-number">01.</h6>
              <p className="text-white feature-content">Peppermint scented!</p>
            </div>
            <canvas
              id="peppermint-Canvas"
              width="250"
              height="20"
              className="position-absolute peppermint-line"
            />
          </div>

          <div
            ref={peppermint}
            className="position-absolute peppermint-block desktop"
          ></div>
          <div
            id="peppermint"
            className={`${"peppermint"} ${
              isPeppermint ? "peppermint-anim" : ""
            } ${isPeppermintNum ? "peppermint-anim" : ""}`}
          >
            <img
              className="position-absolute peppermint-one"
              src={Peppermint}
              alt="Orbit Peppermint"
            />
            <img
              className="position-absolute peppermint-two"
              src={Peppermint}
              alt="Orbit Peppermint"
            />
            <img
              className="position-absolute peppermint-three"
              src={Peppermint}
              alt="Orbit Peppermint"
            />
            <img
              className="position-absolute peppermint-four"
              src={Peppermint}
              alt="Orbit Peppermint"
            />
          </div>
        </>
        {/* 02. On-the-go mistletoe */}
        <>
          <div className="desktop">
            <div
              ref={mistletoeNum}
              className={`${"position-absolute text-left feature-two"} ${
                isMistletoe ? "feature-block" : ""
              } ${isMistletoeNum ? "feature-block" : ""}`}
            >
              <h6 className="first-letter feature-number">02.</h6>
              <p className="text-white feature-content">On-the-go mistletoe</p>
            </div>
            <canvas
              id="mistletoe-Canvas"
              width="200"
              height="30"
              className="position-absolute mistletoe-line"
            />
          </div>
          <div ref={mistletoe} id="mistletoe">
            <img
              className={`${"position-absolute sweater-mistletoe"} ${
                isMistletoe ? "ring" : ""
              } ${isMistletoeNum ? "ring" : ""}`}
              src={Mistletoe}
              alt="Sweater mistletoe"
            />
          </div>
        </>
        {/* 03. Light up!—With the pocket sleeve remote holder. */}
        <>
          <div className="desktop">
            <div
              ref={remoteNum}
              className={`${"position-absolute text-left feature-three"} ${
                isRemote ? "feature-block" : ""
              } ${isRemoteNum ? "feature-block" : ""}`}
            >
              <h6 className="first-letter feature-number">03.</h6>
              <p className="text-white feature-content">
                Light up!—With the pocket sleeve remote holder.
              </p>
            </div>
            <canvas
              id="remote-Canvas"
              width="270"
              height="10"
              className="position-absolute remote-line"
            />
          </div>
          <div
            id="remote"
            ref={remote}
            className={`${"position-absolute sweater-remote"} ${
              isRemoteNum ? "remote" : ""
            }`}
          >
            <img
              className="position-absolute sweater-remote-in"
              src={RemoteIn}
              alt="Sweater Remote"
            />
            <img
              className="position-absolute sweater-remote-out"
              src={RemoteOut}
              alt="Sweater Remote"
            />
          </div>
        </>
        {/* 04. Double-sided Flashy Flip Sequins */}
        <>
          <div className="desktop">
            <div
              ref={sequinNum}
              className={`${"position-absolute text-left feature-four"} ${
                isSequin ? "feature-block" : ""
              } ${isSequinNum ? "feature-block" : ""}`}
            >
              <h6 className="first-letter feature-number">04.</h6>
              <p className="text-white feature-content">Flashy Flip Sequins</p>
            </div>
            <canvas
              id="sequin-Canvas"
              width="340"
              height="25"
              className="position-absolute sequin-line"
            />
          </div>
          <div
            ref={sequin}
            className="position-absolute sweater-sequin-block desktop"
          ></div>
          <div id="sequin">
            <img
              className={`${"position-absolute sweater-sequin"} ${
                isSequin ? "sequinfade" : ""
              }${isSequinNum ? "sequinfade" : ""}`}
              src={Sequin}
              alt="Sweater Sequin"
            />
          </div>
        </>
        {/* 05. Mini multicolor lights */}
        <>
          <div className="desktop">
            <div
              ref={lightsNum}
              className={`${"position-absolute text-left feature-five"} ${
                isLights ? "feature-block" : ""
              } ${isLightsNum ? "feature-block" : ""}`}
            >
              <h6 className="first-letter feature-number">05.</h6>
              <p className="text-white feature-content">
                Mini multicolor lights
              </p>
            </div>
            <canvas
              id="lights-Canvas"
              width="125"
              height="25"
              className="position-absolute lights-line"
            />
          </div>
          <div
            className="position-absolute sweater-lightsoff-block desktop"
            ref={lights}
          ></div>
          <div id="lights">
            <img
              className={`${"position-absolute sweater-lightsoff"} ${
                isLights ? "lightsoff" : ""
              }${isLightsNum ? "lightsoff" : ""}`}
              src={LightsOff}
              alt="Sweater Lights"
            />
          </div>
        </>
        {/* 06. Custom turn dial for easy gum dispensing */}
        <>
          <div className="desktop">
            <div
              ref={dispenserNum}
              className={`${"position-absolute text-left feature-six"} ${
                isDispenser ? "feature-block" : ""
              } ${isDispenserNum ? "feature-block" : ""}`}
            >
              <h6 className="first-letter feature-number">06.</h6>
              <p className="text-white feature-content">
                Custom turn dial for easy gum dispensing
              </p>
            </div>
            <canvas
              id="dispenser-Canvas"
              width="230"
              height="70"
              className="position-absolute dispenser-line"
            />
          </div>
          <div
            ref={dispenser}
            className="position-absolute sweater-dispenser-block desktop"
          ></div>
          <div
            id="dispenser"
            className={`${"sweater-dispenser"} ${
              isDispenser ? "dispenser-anim" : ""
            }${isDispenserNum ? "dispenser-anim" : ""}`}
          >
            <img
              className="position-absolute sweater-dispenser-one"
              src={DispenserOne}
              alt="Sweater Dispenser"
            />
            <img
              className="position-absolute sweater-dispenser-two"
              src={DispenserTwo}
              alt="Sweater Dispenser"
            />
            <img
              className="position-absolute sweater-dispenser-three"
              src={DispenserThree}
              alt="Sweater Dispenser"
            />
            <img
              className="position-absolute sweater-dispenser-four"
              src={DispenserFour}
              alt="Sweater Dispenser"
            />
            <img
              className="position-absolute sweater-dispenser-five"
              src={DispenserFive}
              alt="Sweater Dispenser"
            />
            <img
              className="position-absolute sweater-dispenser-six"
              src={DispenserSix}
              alt="Sweater Dispenser"
            />
          </div>
        </>
        {/* Flashes */}
        <div
          id="flash"
          className={`${"flash"} ${isRemote ? "remote-flash" : ""} ${
            isDispenser ? "dispenser-flash" : ""
          } ${isDispenserNum ? "dispenser-flash" : ""} ${
            isPeppermint ? "peppermint-flash" : ""
          } ${isPeppermintNum ? "peppermint-flash" : ""}`}
        >
          <img
            className="position-absolute flash-one"
            src={Ding}
            alt="Animated flash"
          />
          <img
            className="position-absolute flash-two"
            src={Ding}
            alt="Animated flash"
          />
          <img
            className="position-absolute flash-three"
            src={Ding}
            alt="Animated flash"
          />
        </div>
      </div>
      <div className="mobile position-relative mt-5">
        <Button
          size="lg"
          className="my-2 sequin-button"
          type="submit"
          onClick={() => {
            document.getElementById("sequin").classList.add("sequinfade")
            document
              .getElementById("peppermint")
              .classList.remove("peppermint-anim")
            document.getElementById("mistletoe").classList.remove("ring")
            document.getElementById("remote").classList.remove("mobile-remote")
            document
              .getElementById("dispenser")
              .classList.remove("mobile-dispenser-anim")
            setTimeout(
              () =>
                document
                  .getElementById("sequin")
                  .classList.remove("sequinfade"),
              5000
            )
          }}
        >
          Flashy Flip Sequins
        </Button>
        <Button
          size="lg"
          className="mb-2 lights-button"
          type="submit"
          onClick={() => {
            document.getElementById("lights").classList.add("lightsoff-ani")
            document
              .getElementById("peppermint")
              .classList.remove("peppermint-anim")
            document.getElementById("mistletoe").classList.remove("ring")
            document.getElementById("remote").classList.remove("mobile-remote")
            document
              .getElementById("dispenser")
              .classList.remove("mobile-dispenser-anim")
            setTimeout(
              () =>
                document
                  .getElementById("lights")
                  .classList.remove("lightsoff-ani"),
              5000
            )
          }}
        >
          Mini multicolor lights
        </Button>
        <Button
          size="lg"
          className="mb-2 dispenser-button"
          type="submit"
          onClick={() => {
            document
              .getElementById("dispenser")
              .classList.add("mobile-dispenser-anim")
            document.getElementById("flash").classList.add("dispenser-flash")
            document
              .getElementById("peppermint")
              .classList.remove("peppermint-anim")
            document.getElementById("mistletoe").classList.remove("ring")
            document.getElementById("remote").classList.remove("mobile-remote")
            document.getElementById("sequin").classList.remove("sequinfade")
            setTimeout(() => {
              document
                .getElementById("dispenser")
                .classList.remove("mobile-dispenser-anim")
              document
                .getElementById("flash")
                .classList.remove("dispenser-flash")
            }, 2000)
          }}
        >
          Custom turn dial for easy{" "}
          <span className="d-block">gum dispensing</span>
        </Button>
      </div>
    </section>
  )
}

export default SweaterAnimation
