import React, { useState } from "react"
import Layout from "../../components/orbit components/components/layout"
import HomePage from "../../components/orbit components/components/homepage"
import AgeGate from "../../components/orbit components/components/agegate"

const IndexPage = () => {
  const [agegate, setAgeGate] = useState(false)
  const [homePage, setHomePage] = useState(true)
  return (
    <Layout>
      {agegate && <AgeGate setAgeGate={setAgeGate} setHomePage={setHomePage} />}
      {homePage && <HomePage />}
    </Layout>
  )
}
export default IndexPage