import React, { useRef } from "react"

import Background from "../images/home-background.png"
import MobileBackground from "../images/mobile-home-background.png"
import Ding from "../images/ding.png"
import OrbitLogo from "../images/Orbit-logo.png"
import GYDB from "../images/GYDB.png"
import DesktopBlueGumShare from "../images/blueGum-share.png"
import MobileBlueGumShare from "../images/blueGum-share-mobile.png"

import HisAndHer from "../images/his-her-with-ding.png"
import MobileHisAndHer from "../images/mobile-his-her.png"
import Pattern from "../images/pattern.svg"
import FacebookLogo from "../images/facebook.png"
import InstaLogo from "../images/blue-insta.png"
import TwitterLogo from "../images/blue-twitter.png"

import SweaterCarousel from "./sweaterCarousel"
import SweaterAnimation from "./sweaterAnimation"
import SignUpForm from "./Forms/signup"
import SignInForm from "./Forms/signin"
import Lights from "./lights"

const HomePage = () => {
  const goToHome = () => window.location.reload()
  const scrollToRef = ref => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }
  const myRef = useRef(null)

  return (
    <>
      <div className="home-page">
        <div className="common">
          <section className="main">
            <img
              className="position-relative w-100 h-auto header-background"
              src={Background}
              alt="Header Background"
            />
            <img
              className="position-relative w-100 h-auto header-mobile-background"
              src={MobileBackground}
              alt="Header Background"
            />

            <img
              src={Ding}
              alt="flash light"
              className="position-absolute her-ding"
            />
            <img
              src={Ding}
              alt="flash light"
              className="position-absolute his-ding"
            />
            <img
              src={OrbitLogo}
              alt="Orbit Logo"
              className="position-absolute orbit-logo"
              onClick={goToHome}
            />

            <div>
              <img
                src={GYDB}
                alt="GYDB Logo"
                className="position-absolute gydb"
              />
            </div>
            <div className="position-absolute banner-heading">
              <h1 className="text-uppercase">
                <span className="text-white d-block">Ugly Sweater.</span>Fresh
                <span className="d-md-block"> breath.</span>
              </h1>
            </div>
          </section>
          <div className="position-relative">
            <Lights />
          </div>

          <section className="information">
            <div className="container description">
              <h2 className="text-white mb-0">
                DON’T LET{" "}
                <span className="d-mb-block">
                  <span className="first-letter">F</span>EAR{" "}
                  <span className="first-letter">O</span>F{" "}
                  <span className="first-letter">M</span>AKING{" "}
                  <span className="first-letter">O</span>UT{" "}
                </span>
              </h2>
              <h2 className="text-white">CRAMP YOUR HOLIDAY SEASON</h2>
              <p className="text-white">
                <span className="heading">
                  #GETYOURDINGBACK WITH ORBIT® GUM
                </span>
                <span className="d-block content">
                  ORBIT® Gum is here to help you{" "}
                  <span className="heading">#GetYourDingBack</span> this holiday
                  season by providing you with the freshest ’fit to get you
                  feeling confident to conquer any holiday party. While we can’t
                  help you resist the delicious, yet malicious, garlic minced up
                  in holiday dishes, we can be your back-pocket, good-breath
                  guru for those mistletoe moments, so you can dodge those
                  awkward smooches with ease.
                </span>{" "}
                <span className="heading">
                  Unlike ORBIT® Gum, The Smooching Sweater Sweepstakes Won’t
                  Last Long
                </span>
              </p>
            </div>
            <Lights />
          </section>

          <div className="sweater">
            {/* <section className="container sweater-style">
              <h3 className="text-white">LONG-LASTING SWEATER STYLE</h3>
              <p className="text-white">
                <span className="heading">
                  TIME TO GET KNIT-PICKY. ENTER OUR ORBIT® SMOOCHING SWEATER
                  SWEEPSTAKES.
                </span>
                <span className="d-block content">
                  Style up your good breath with a flashy blue bedazzled
                  sweater.
                </span>
                <span className="d-block content">
                  You’ll be the life of your ugly sweater party while remaining
                  fresh and confident.
                </span>
              </p>
            </section> */}

            <section className="container sweater-style sign-in">
              <h3 className="text-white">
                SORRY TO BURST YOUR BUBBLE, BUT OUR SMOOCHING SWEATER
                SWEEPSTAKES HAVE CLOSED!
              </h3>
              <p className="text-white">
                Even though you didn’t get to take home an ORBIT® Ugly Sweater,
                you can keep <span className="first-letter fw-bold">F</span>
                ear <span className="first-letter fw-bold">O</span>f{" "}
                <span className="first-letter fw-bold">M</span>aking{" "}
                <span className="first-letter fw-bold">O</span>ut out of your
                holiday plans when you chew on ORBIT® Gum.
              </p>
            </section>
            <SweaterAnimation />
            {/* <div ref={myRef}>
              <SignUpForm />
            </div> */}
            <div ref={myRef}>
              <SignInForm />
            </div>
            <Lights />
          </div>

          <section className="sweater-carousel">
            <SweaterCarousel />
          </section>
        </div>
        <section className="his-her-pattern">
          <img
            className="position-relative w-100 h-auto desktop"
            src={HisAndHer}
            alt="His and Her with Ding"
          />
          <img
            className="position-relative w-100 h-auto mobile"
            src={MobileHisAndHer}
            alt="His and Her with Ding"
          />
          <img
            src={Pattern}
            alt="Zig Zag pattern"
            className="position-absolute pattern"
          />
        </section>

        <section className="sweater-experience position-relative">
          <div className="container experience">
            <h2 className="text-white">
              <span className="word-blue">SHARE</span> YOUR UGLY{" "}
              <span className="d-block">SWEATER EXPERIENCE</span>
            </h2>
            <p>
              ORBIT® Gum Mega Packs are meant to be shared—tag friends that
              you’ll spread the smell of minty freshness with this holiday
              season!{" "}
              <span className="sky-blue d-mb-block">#GetYourDingBack</span>
            </p>
            <div className="social-icons d-flex">
              <a
                href="https://www.facebook.com/orbitgum"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="facebook-icon">
                  <img
                    src={FacebookLogo}
                    className="social-logo"
                    alt="FaceBook logo"
                  />
                </div>
              </a>
              <span className="visually-hidden">Opens a new window</span>
              <a
                href="https://www.twitter.com/OrbitGum"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="twitter-icon">
                </div>
              </a>
              <span className="visually-hidden">Opens a new window</span>
              <a
                href="https://www.instagram.com/orbitgum"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="instagram-icon">
                  
                </div>
              </a>
              <span className="visually-hidden">Opens a new window</span>
            </div>
            <div classNamae="position-relative">
              <img
                src={DesktopBlueGumShare}
                alt="Blue Gum"
                className="position-absolute desktop share-blue-gum"
              />
              <img
                src={MobileBlueGumShare}
                alt="Blue Gum"
                className="position-absolute mobile share-blue-gum"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default HomePage
